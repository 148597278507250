import { Link, graphql } from 'gatsby'
import AppBorder from '../components/AppBorder'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import SEO from '../components/Seo'
import styled from 'styled-components'

const ProjectNav = styled.div`
  display: flex;

  a:last-child {
    margin-left: auto;
  }
`

export default function PageTemplate ({ data: { mdx: project } }) {
  if (!project) {
    return <p>Oh dear.</p>
  }

  return (
    <Layout>
      <SEO title={`Project - ${project.frontmatter.shortTitle} - Daniel Swaine`} />
      <AppBorder
        title={`Project - ${project.frontmatter.shortTitle}`}
        colour='#fff'>
        <ProjectNav>
          <Link to='/projects'>&larr; back to projects</Link>
          <a target='_blank' href={project.frontmatter.link} rel='noreferrer'>
            visit the site &rarr;
          </a>
        </ProjectNav>
        <h2 style={{margin: '0.5em 0 0.5em 0'}}>
          {project.frontmatter.title}
        </h2>
        <MDXRenderer>{project.body}</MDXRenderer>
        <Img fluid={project.frontmatter.featuredImage.childImageSharp.fluid} />
      </AppBorder>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        shortTitle
        link
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
